@import 'scss/theme.scss';

.asset-order-book {
  background-color: $color-grey-light;
}

.asset-order-book__title {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid $color-dark;
  font-weight: bold;
}

.asset-order-book__title--bid {
  background-color: $color-green;
  color: white;
}
.asset-order-book__title--price {
  color: $color-dark;
  background-color: $color-mid;
}
.asset-order-book__title--ask {
  color: white;
  background-color: $color-ask;
}

.asset-order-book__line {
  height: 24px;
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: $color-grey-light;
  padding: 0 4px;
  border-bottom: 1px solid $color-grey-light;
}
.asset-order-book__line--bid {
  background-color: $color-green;
  color: white;
  justify-content: center;
  border-bottom: 1px solid $color-dark;
}
.asset-order-book__line--price {
  background-color: $color-mid;
  color: $color-dark;
  justify-content: center;
  border-bottom: 1px solid $color-dark;
}
.asset-order-book__line--ask {
  background-color: $color-ask;
  color: white;
  justify-content: center;
  border-bottom: 1px solid $color-dark;
}

.asset-order-book__desc {
  width: 100%;
  color: $color-dark;
  padding: 16px 12px;
}

.asset-order-book__desc-line {
  margin-right: 8px;
  font-weight: bold;
  text-transform: capitalize;
}
.asset-order-book__desc-title {
  min-width: 60px;
}


$color-theme-main: #1B5B7E;
$color-theme-sub: #4682b4;
$color-green: #66BB6A;
$color-red: #FB0400;

$color-grey-light: #e8e8e8;
$color-white-smoke: #f5f5f5;
$color-grey-dark: #898989;

$color-dark: #333333;
$color-dark-hover: #464646;

$color-bid: #295090;
$color-mid: #FCC102;
$color-ask: #FB0400;

@import 'scss/theme.scss';

.market-activities-table {
  width: 100%;
  max-height: 100vh;
  position: relative;
  overflow: auto;
  background-color: $color-theme-main;
  color: white;
  max-height: 600px;
  * {
    border-color: transparent !important;
    color: white;
  }
  table thead th {
    background-color: $color-theme-main;
  }
}


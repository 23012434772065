@import 'scss/theme.scss';

.market-chart {
  background-color: $color-dark;
  width: 100%;
  height: 375px;
  padding: 12px;
  padding-left: 0;
  .recharts-cartesian-axis-tick text {
    fill: white;
  }
  .section-title {
    margin-bottom: 24px;
  }
  svg {
    overflow: visible;
  }
}

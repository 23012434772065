@import 'scss/theme.scss';

.market-trend-table {
  width: 100%;
  height: 235px;
  position: relative;
  overflow: hidden;
  background-color: $color-theme-main;
  color: white;
  text-align: center;
  * {
    border-color: transparent !important;
    color: white;
  }
  thead th {
    background-color: $color-theme-main;
  }
}
.market-trend-table__table-container {
  width: 100%;
  overflow: auto;
}

.market-trend-table__positive {
  color: $color-green;
}

.market-trend-table__negative {
  color: $color-red;
}

@import 'scss/theme.scss';

.asset-trade-form {
  width: 100%;
  padding: 10px;
  height: 225px;
  background-color: $color-theme-main;
  color: white;
}

.asset-trade-form__line {
  min-height: 28px;
  display: flex;
  align-items: center;
  margin: 2px 0;
}

.asset-trade-form__asset-desc-key {
  padding-right: 6px;
  min-width: 60px
}

.asset-trade-form__asset-desc-value {
  padding-right: 20px;
}

.asset-trade-form__field-bid {
  input {
    width: 80px;
    text-align: right;
    padding: 2px 4px;
    border: 0;
    text-align: center;
  }
}

.asset-trade-form__field-bid-title {
  justify-content: center;
  width: 100%;
}

.asset-trade-form__line--trade {
  margin-top: 24px;
}

.asset-trade-form__btn {
  padding: 8px 24px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}
.asset-trade-form__buy-btn {
  background-color: $color-green;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 0px;
  color: white;
  font-weight: bold;
}
.asset-trade-form__sell-btn {
  background-color: $color-red;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 0px;
  color: white;
  font-weight: bold;
}
.asset-trade-form__trade-btn {
  background-color: $color-theme-main;
  border-radius: 4px;
  border: 0px;
  padding: 8px 24px;
  color: white;
  font-weight: bold;
  margin-left: 24px;
}

@import 'scss/theme.scss';

.ui-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ui-list__item {
  &:hover {
    background-color: $color-grey-light;
  }
}

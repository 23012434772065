@import 'scss/theme.scss';

.user-trade-history {
  width: 100%;
  height: 280px;
  background-color: white;
  position: relative;
  overflow: hidden;
}

.user-trade-history__table-container {
  width: 100%;
  overflow: auto;
  table {
    margin-bottom: 0;
  }
  thead th {
    background-color: white;
  }
}

.user-trade-history__status {
  text-transform: capitalize;
  color: $color-dark;
}

.user-trade-history__status-green {
  color: $color-green;
}

.user-trade-history__status-red {
  color: $color-ask;
}

.user-trade-history__action {
  text-transform: capitalize;
}

.user-trade-history__action-buy {
  color: $color-green;
}

.user-trade-history__action-sell {
  color: $color-ask;
}

.user-trade-history__scroll-to-all-assets {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 48px;
  text-align: center;
  background-color: $color-theme-main;
  color: white;
  border: 0;
  border-left: 1px solid white;
}

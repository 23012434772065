@import 'scss/theme.scss';

.loading {
  width: 100%;
  height: 60px;
  text-align: center;
  padding: 12px;

  .loading__dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: $color-theme-main;
    margin: 0 4px;
  }
  .loading__dot:nth-last-child(1) {animation: loadingC .6s .1s linear infinite;}
  .loading__dot:nth-last-child(2) {animation: loadingC .6s .2s linear infinite;}
  .loading__dot:nth-last-child(3) {animation: loadingC .6s .3s linear infinite;}
}

@keyframes loadingC {
  0 {transform: translate(0,0);}
  50% {transform: translate(0,15px);}
  100% {transform: translate(0,0);}
}

.loading--white {
  .loading__dot {
    background-color: white;
  }
}

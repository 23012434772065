@import 'scss/theme.scss';

.led-text-headline {
  width: 100%;
  padding: 6px;
  background-color: white;
  height: 32px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.led-text-headline__scroll-wrapper {
  height: 32px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: white;;
  color: white;
  line-height: 0px;
  display: flex;
  align-items: center;
}

.led-text-headline__scroll-text-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 32px;
  text-align: center;
  /* Starting position */
  -moz-transform:translateX(0);
  -webkit-transform:translateX(0);
  transform:translateX(0);
  /* Apply animation to this element */
  -moz-animation: scroll-left 30s linear infinite;
  -webkit-animation: scroll-left 30s linear infinite;
  animation: scroll-left 30s linear infinite;

  > span {
    display: inline-block;
    margin-right: 16px;
  }
}

.led-text-headline__scroll-text-wrapper--second {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 32px;
  text-align: center;
  /* Starting position */
  -moz-transform:translateX(100%);
  -webkit-transform:translateX(100%);
  transform:translateX(100%);
  /* Apply animation to this element */
  -moz-animation: scroll-left 30s linear 15s infinite;
  -webkit-animation: scroll-left 30s linear 15s infinite;
  animation: scroll-left 30s linear 15s infinite;

  > span {
    display: inline-block;
    margin-right: 16px;
  }
}

.led-text-headline__scroll-text {
  color: $color-dark;
  text-transform: capitalize;
  > span {
    display: inline-block;
    margin-left: 4px;
  }
}
.led-text-headline__scroll-text--unchange > span {
  color: $color-white-smoke;
}
.led-text-headline__scroll-text--green  > span {
  color: $color-green;
}
.led-text-headline__scroll-text--red  > span {
  color: red;
}
/* Move it (define the animation) */
@-moz-keyframes scroll-left {
  0%   { -moz-transform: translateX(100%); }
  100% { -moz-transform: translateX(-100%); }
}
@-webkit-keyframes scroll-left {
  0%   { -webkit-transform: translateX(100%); }
  100% { -webkit-transform: translateX(-100%); }
}
@keyframes scroll-left {
  0%   {
    -moz-transform: translateX(100%); /* Browser bug fix */
    -webkit-transform: translateX(100%); /* Browser bug fix */
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%); /* Browser bug fix */
    -webkit-transform: translateX(-100%); /* Browser bug fix */
    transform: translateX(-100%);
  }
}

@import 'scss/theme.scss';

.simulator-modal {
  position: fixed;
  left: 12px;
  top: 120px;
  z-index: 10001;
  min-width: 200px;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 0 1px 1px $color-grey-dark;
  background-color: white;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-30px);
  transition: all 0.3s ease;

  input {
    margin-left: 8px;
  }
  button {
    width: 100%;
    margin: 4px 0;
    display: block;
    background-color: $color-dark;
    color: white;
    border: 0;
    padding: 4px;
  }
}

.simulator-modal--visible {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

.simulator-modal__line {
  margin: 2px 0;
  width: 100%;
}

.simulator-modal__field-label {
  min-width: 100px;
}


.simulator-modal__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
}

.simulator-modal__overlay--visible {
  display: block;;
}

@import 'scss/theme.scss';

.asset-list {
  width: 200px;
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 32px;
  bottom: 0;
  background-color: $color-theme-main;
  z-index: 9999;
  box-shadow: 0 2px 2px 1px $color-dark;
}

.asset-list__search {
  padding: 2px;
  input {
    width: 100%;
    padding: 0 6px;
    &:focus {
      outline: none;
    }
  }
}

.ui-list__item {
  a {
    display: block;
    padding: 6px 12px;
    color: white;
    text-transform: capitalize;
    &:hover {
      color: $color-theme-main;
    }

    &.asset-list__link--active {
      background-color: $color-grey-light;
      color: $color-theme-main;
    }
  }
}

.asset-list__market-making {
  text-align: center;
  color: white;
  border-bottom: 1px solid $color-white-smoke;
  margin-bottom: 8px;
  label {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }
  input {
    margin-right: 4px;
  }
}

.asset-list__logo {
  width: 64px;
  height: 64px;
  margin: 8px auto;
  display: block;
}

.asset-list__list {
  padding-bottom: 50px;
  position: relative;
}

.asset-list__market-offset {
  position: absolute;
  bottom: 0;
  height: 40px;
  color: white;
  width: 100%;
  border-top: 1px solid $color-grey-light;
  label {
    cursor: pointer;
    margin-bottom: 0;
  }
  input {
    margin-right: 8px;
  }
}

.asset-list__control {
  button {
    background-color: $color-dark;
    color: white;
    border: 0;
    border-radius: 4px;
    padding: 6px 16px;
    margin-bottom: 12px;
    &:hover {
      background-color: $color-dark-hover;
    }
  }
}

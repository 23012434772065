.ui-flex {
  display: flex;

  &--horizontal {
    flex-direction: row;
  }

  &--vertical {
    flex-direction: column;
  }

  &--align-flex-start {
    align-items: flex-start;
  }

  &--align-center {
    align-items: center;
  }

  &--align-stretch {
    align-items: stretch;
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-flex-start {
    justify-content: flex-start;
  }
}

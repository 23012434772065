@import 'scss/theme.scss';

.asset-global-trade-board {
  max-height: 100vh;
  width: 100%;
  padding-left: 200px;
  background-color: $color-theme-main;
  color: white;
  padding-top: 32px;
  position: relative;
}
